<template>
    <v-form v-if="!completed" @submit.prevent="submit" v-model="isFormValid" lazy-validation>
        <p class="mb-6 text-justify">{{ $t('forms.ForgotPasswordForm.description') }}</p>

        <div class="forgot-password-form">
            <cp-text-field
                ref="identifier_input"
                autofocus
                v-model="identifier"
                type="identifier"
                :label="$t('forms.ForgotPasswordForm.identifier.label')"
                :hint="$t('forms.ForgotPasswordForm.identifier.hint')"
                :placeholder="$t('forms.ForgotPasswordForm.identifier.placeholder')"
                :disabled="loading"
                :rules="rules"
                required
            />

            <v-btn type="submit" color="primary" block class="my-3" elevation="0" height="42px" :disabled="!isFormValid" :loading="loading">{{ $t('actions.send') }}</v-btn>
            <GoBack class="mt-12 mb-4" goBack="auth.email_login" />
        </div>

    </v-form>
    <div v-else>
        <v-alert text outlined type="success">{{ $t('forms.ForgotPasswordForm.success') }}</v-alert>

        <GoBack class="mt-12 mb-4" goBack="auth.email_login" />
    </div>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import SimpleCustomer       from '@/models/SimpleCustomer'
import { required, length } from '@/utils/validation'
import GoBack from './GoBack'

export default {
    name: 'ResetPasswordEmail',
    data: vm => ({
        loading    : false,
        isFormValid: false,
        completed  : false,
        identifier : '',
        rules      : [required.and(length(0, 191))],
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { GoBack },
    mounted() {
        this.$emit('hideWelcomeBanner')
    },
    methods: {
        async submit() {
            if (!this.isFormValid)
                return

            this.identifier = this.identifier.toLowerCase().trim()

            this.loading = true
            let res = await SimpleCustomer.resetPassword(this.identifier)
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.completed = true
        },

        async preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')
            if (status == 422) {
                let code = this.$lodash.get(e, 'response.data.code')

                if (code == 'use-social-login-instead') {
                    await this.$bus.$emit('alert', this.$t('forms.LoginForm.use_social_login_instead'), 'error')
                    return
                }
            }

            this.errorHandler(e)
        }
    },
}
</script>

<style scoped lang="scss">
.forgot-password-form {
    max-width: 300px;
    margin: auto;
}
</style>