var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.completed
    ? _c(
        "v-form",
        {
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c("p", { staticClass: "mb-6 text-justify" }, [
            _vm._v(_vm._s(_vm.$t("forms.ForgotPasswordForm.description")))
          ]),
          _c(
            "div",
            { staticClass: "forgot-password-form" },
            [
              _c("cp-text-field", {
                ref: "identifier_input",
                attrs: {
                  autofocus: "",
                  type: "identifier",
                  label: _vm.$t("forms.ForgotPasswordForm.identifier.label"),
                  hint: _vm.$t("forms.ForgotPasswordForm.identifier.hint"),
                  placeholder: _vm.$t(
                    "forms.ForgotPasswordForm.identifier.placeholder"
                  ),
                  disabled: _vm.loading,
                  rules: _vm.rules,
                  required: ""
                },
                model: {
                  value: _vm.identifier,
                  callback: function($$v) {
                    _vm.identifier = $$v
                  },
                  expression: "identifier"
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "my-3",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    block: "",
                    elevation: "0",
                    height: "42px",
                    disabled: !_vm.isFormValid,
                    loading: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.send")))]
              ),
              _c("GoBack", {
                staticClass: "mt-12 mb-4",
                attrs: { goBack: "auth.email_login" }
              })
            ],
            1
          )
        ]
      )
    : _c(
        "div",
        [
          _c(
            "v-alert",
            { attrs: { text: "", outlined: "", type: "success" } },
            [_vm._v(_vm._s(_vm.$t("forms.ForgotPasswordForm.success")))]
          ),
          _c("GoBack", {
            staticClass: "mt-12 mb-4",
            attrs: { goBack: "auth.email_login" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }